import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { i18n as i18nOtherLib } from '@sistemiv/s-components'

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(detector)
  .init({
    lng: localStorage.getItem('lng') || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

i18n.on('languageChanged', (lng) => {
  i18nOtherLib.changeLanguage(lng)
})

export default i18n
