// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@sistemiv/s-components/dist/TC.css'
import LoadingLogo from './assets/loading_logo.png'
import { msalInstance } from './services/AuthConfig'
import { EventType } from '@azure/msal-browser'
import './services/i18n'

msalInstance.initialize().then(() => {
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
    document.cookie = `user=${msalInstance.getAllAccounts()[0].localAccountId}; path=/; max-age=${60 * 60 * 24 * 14}`
  }

  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.SSO_SILENT_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) {
      const account = event.payload?.account
      msalInstance.setActiveAccount(account)
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      document.cookie = `refreshIdentity=true; path=/; max-age=3; domain=.sistemiv.com`
    }
  })
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.Suspense
    fallback={
      <div className='w-screen h-screen flex items-center justify-center'>
        <img src={LoadingLogo} />
      </div>
    }
  >
    <App pca={msalInstance} />
  </React.Suspense>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
