import { useInfiniteQuery } from '@tanstack/react-query'
import OrganizationService from '../../services/Organization.service'
import { OrganizationResponse } from '../../models/Organization.model'

type OrganizationsOptions = {
  userId?: string
  size?: number
  search?: string
}

export const useOrganizations = ({ userId, ...payload }: OrganizationsOptions) => {
  return useInfiniteQuery<OrganizationResponse>({
    queryKey: ['organizations', userId, payload],
    queryFn: ({ pageParam }) => OrganizationService.list(pageParam as number, payload.size, payload.search),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _2, lastPageParam) => {
      if (lastPage.organizations.length === 0) {
        return undefined
      }
      return Number(lastPageParam) + 1
    },
    enabled: !!userId,
  })
}
