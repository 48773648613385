import React, { FC } from 'react'
import lockIcon from '../assets/icons/lock-icon.png'
import { Button, EmptyPage } from '@sistemiv/s-components'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'

const NotAuthorized: FC = () => {
  const { instance } = useMsal()
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <EmptyPage imageUrl={lockIcon} imageAlt={'Not authorized'} message={t('notAuthorized')}>
      <Button className='text-sky-500 text-lg hover:bg-gray-100 transition-all' onClick={() => navigate(-1)}>
        {t('goBack')}
      </Button>
      <Button
        className='text-sky-500 text-lg hover:bg-gray-100 transition-all'
        onClick={() => instance.logoutRedirect()}
      >
        {t('logInFromOtherAcc')}
      </Button>
    </EmptyPage>
  )
}

export default NotAuthorized
