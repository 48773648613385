// import { Popover } from '@headlessui/react'
// import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React, { FC } from 'react'
import { Application } from './OrganizationPermissionsSection'
// import ExpandablePermissionItem from './ExpandablePermissionItem'

type OrganizationPermissionsAppProps = {
  readonly: boolean
  apps: Application[]
}
const OrganizationPermissionsApp: FC<OrganizationPermissionsAppProps> = ({ readonly, apps }) => {
  return (
    <div>
      <div className='grid grid-cols-2 gap-y-4 p-4 rounded bg-gradient-to-b from-[#E8E9EE] to-[#F3F7FB]'>
        {apps.map((app, index) => (
          <React.Fragment key={index}>
            <div className='flex items-center'>
              <input
                type='checkbox'
                className='cursor-pointer h-5 w-5 text-sky-500 rounded disabled:cursor-default disabled:text-gray-300 border-2 border-gray-300 focus:outline-none focus:ring-0 focus:ring-offset-0'
                checked={app.accessible}
                disabled={readonly}
              />
              <p className='capitalize font-medium text-[#363943] ml-2 wihtespace-nowrap text-ellipsis '>{app.name}</p>
            </div>

            <p className='capitalize text-left text-[#5C6375] wihtespace-nowrap text-ellipsis' key={index}>
              {app.roles?.[0]?.name}
            </p>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default OrganizationPermissionsApp
