import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'

type UpdateUserPhotoOptions = {
  userId: string
  image: File
}

export const useUpdateUserPhoto = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateUserPhotoOptions>({
    mutationKey: ['updateUserPhoto'],
    mutationFn: (payload) => UserService.updatePhoto(payload.image),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['user', variables.userId] })
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries()
      const previousUser = queryClient.getQueryData(['user', variables.userId])
      queryClient.setQueryData(['user', variables.userId], (old: any) => ({
        ...old,
        photo: variables.image,
      }))
      return { previousUser }
    },
  })
}
