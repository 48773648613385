import { useQuery } from '@tanstack/react-query'
import { AppRolesResponse } from '../../models/UserProfile'
import UserService from '../../services/UserService'

type UserAppRolesOptions = {
  userId?: string
  organizationId?: string
}

export const useUserAppRoles = ({ userId, organizationId }: UserAppRolesOptions) => {
  return useQuery<AppRolesResponse>({
    queryKey: ['user-app-roles', userId, organizationId],
    queryFn: () => UserService.getAppRoles(organizationId!),
    enabled: !!userId && !!organizationId,
  })
}
