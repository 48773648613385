import { useMsal } from '@azure/msal-react'
import { Menu, Transition } from '@headlessui/react'
import { GlobeAltIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import {
  AppIcon,
  ImgWrapper,
  NavigationBar,
  Option,
  Select,
  SistemivLogoIcon,
  SpinnerIcon,
} from '@sistemiv/s-components'
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { imageBase, portalUrl } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import { useMyOrganizations } from '../../repositories/organizations/organizations-my.respository'
import { useGetApplications } from '../../repositories/users/user-applications.repository'
import { useResponsive } from '../context/ResponsiveContextProvider'
import { ArrowRightIcon, CheckIcon } from '@heroicons/react/24/solid'
import AppsIcon from '../../assets/icons/AppsIcon'

export type OrgType = {
  id: string
  code: string
  image: string
  name: string
}

const TopNav: FC = () => {
  const navigate = useNavigate()
  const { instance } = useMsal()
  const { t, i18n } = useTranslation()
  const { data: organizationsPages } = useMyOrganizations({
    userId: instance.getActiveAccount()?.localAccountId,
  })
  const organizations: OrgType[] = useMemo(
    () =>
      organizationsPages?.pages
        .flat(Infinity)
        .map((r) => r.organizations)
        .flat()
        ?.map((o) => ({ id: o.organizationCode, code: o.organizationCode, image: o.icon, name: o.name })) ?? [],
    [organizationsPages],
  )
  const [org, setOrg] = useState<OrgType | null>(organizations.length > 0 ? organizations[0] : null)
  const { data: applications, isLoading: appsLoading } = useGetApplications(org ? org?.code : '')
  const { isMobile } = useResponsive()

  useEffect(() => {
    if (organizations.length > 0) {
      setOrg(organizations[0])
    }
  }, [organizations])

  const handleLogout = async () => {
    window.location.replace(`${portalUrl}/logout`)
  }

  const locales = [
    { value: 'en', title: 'English (en)' },
    { value: 'sr', title: 'Srpski (sr)' },
  ]

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('lng', lng)
  }

  return (
    <NavigationBar className='h-[4.185rem] border-b border-gray-300 z-20 flex items-center justify-between'>
      {isMobile ? (
        <div className='flex items-center gap-x-2 select-none'>
          <button onClick={() => navigate(-1)} className='rounded-full hover:bg-sky-50 p-2'>
            <ArrowLeftIcon className='w-6 h-6 text-gray-400' />
          </button>
          <p className='text-[#4b4f57] font-bold'>{t('myProfile').toUpperCase()}</p>
        </div>
      ) : (
        <div className='flex items-center gap-x-2 cursor-pointer' onClick={() => navigate('/')}>
          <SistemivLogoIcon className='text-[#2dcbfb] w-[40px] h-[30px]' />
          <p className='text-[#4b4f57] font-bold'>{t('myProfile').toUpperCase()}</p>
        </div>
      )}
      <div className='flex items-center gap-x-3 md:gap-x-6'>
        <Menu as='div' className='relative'>
          <Menu.Button
            name='nav-language'
            className='inline-flex w-full justify-center items-center md:gap-x-2 md:px-1 py-2 rounded-md hover:bg-sky-50 active:bg-sky-100'
          >
            <GlobeAltIcon className='h-6 w-6' />
            {!isMobile && (
              <div data-testid='current-language' className='md:block'>
                {i18n.resolvedLanguage}
              </div>
            )}
          </Menu.Button>
          {isMobile && (
            <Transition
              as={Fragment}
              enter='transition-opacity duration-80'
              enterFrom='opacity-0'
              enterTo='opacity-40'
              leave='transition-opacity duration-80'
              leaveFrom='opacity-40'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
            </Transition>
          )}
          <Transition
            as={Fragment}
            enter={isMobile ? 'transition-transform duration-80 ease-out' : ''}
            enterFrom={isMobile ? 'transform translate-y-full' : ''}
            enterTo={isMobile ? 'transform translate-y-0' : ''}
            leave={isMobile ? 'transition-transform duration-80 ease-in' : ''}
            leaveFrom={isMobile ? 'transform translate-y-0' : ''}
            leaveTo={isMobile ? 'transform translate-y-full' : ''}
          >
            <Menu.Items
              className={
                isMobile
                  ? 'md:hidden fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg z-20 py-4 px-4 gap-y-1'
                  : 'absolute right-0 z-10 mt-2 py-4 px-4 gap-y-3 w-56 origin-top-right flex flex-col bg-white drop-shadow rounded shadow-xl ring-1 ring-black ring-opacity-5'
              }
            >
              {isMobile && (
                <div className='md:hidden w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
              )}
              {locales.map((locale) => (
                <Menu.Item key={`language-${locale.value}`} data-testid='nav-language'>
                  {() => (
                    <div
                      onClick={() => handleChangeLng(locale.value)}
                      className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                      data-testid='nav-language'
                    >
                      {isMobile && (
                        <span className='mr-5'>
                          <span className='absolute'>
                            {i18n.language === locale.value ? <CheckIcon className='h-5 w-5' aria-hidden='true' /> : ''}
                          </span>
                        </span>
                      )}
                      {locale.title}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
        {organizations.length > 0 && org && (
          <Menu as='div' className='relative'>
            <Menu.Button
              className='inline-flex w-full justify-center items-center gap-x-2 p-2 rounded-full hover:bg-sky-50 active:bg-sky-100'
              name='apps-btn'
            >
              <AppsIcon />
            </Menu.Button>
            {isMobile && (
              <Transition
                as={Fragment}
                enter='transition-opacity duration-80'
                enterFrom='opacity-0'
                enterTo='opacity-40'
                leave='transition-opacity duration-80'
                leaveFrom='opacity-40'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
              </Transition>
            )}
            <Transition
              as={Fragment}
              enter={isMobile ? 'transition-transform duration-80 ease-out' : ''}
              enterFrom={isMobile ? 'transform translate-y-full' : ''}
              enterTo={isMobile ? 'transform translate-y-0' : ''}
              leave={isMobile ? 'transition-transform duration-80 ease-in' : ''}
              leaveFrom={isMobile ? 'transform translate-y-0' : ''}
              leaveTo={isMobile ? 'transform translate-y-full' : ''}
            >
              <Menu.Items
                className={
                  isMobile
                    ? 'md:hidden fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg z-20 py-4 px-4 gap-y-1'
                    : 'absolute right-0 z-10 mt-2 py-4 px-4 gap-y-3 w-56 origin-top-right flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'
                }
              >
                {isMobile && (
                  <div className='md:hidden w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                )}
                <Menu.Item>
                  <Select
                    type='organization'
                    value={org ?? undefined}
                    disabled={organizations.length <= 1}
                    onChange={(e) => {
                      setOrg(e)
                    }}
                    className='mt-4 mb-2'
                    topMenu={isMobile}
                  >
                    {organizations
                      .filter((o) => o.code !== org.code)
                      .map((o) => (
                        <Option key={o.code} value={o}>
                          <div className='pb-1 hover:bg-blue-50 cursor-pointer flex'>
                            <ImgWrapper
                              src={`${imageBase}/${o.code}/76`}
                              className='w-6 h-6 rounded-full mr-2 flex items-center justify-center border border-gray-300'
                              onErrorComponent={
                                <p className='text-md text-white w-6 h-6 mr-2 rounded-full flex items-center justify-center bg-[#7b87ae]'>
                                  {o.name.charAt(0).toUpperCase()}
                                </p>
                              }
                            />
                            <span> {o.name}</span>
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Menu.Item>
                {appsLoading && (
                  <Menu.Item>
                    <div className='w-full flex justify-center'>
                      <SpinnerIcon className='w-6 h-6 text-sky-500' />
                    </div>
                  </Menu.Item>
                )}
                {applications?.map((app, index) => {
                  if (isMobile && app.name === 's-organization') return null
                  return (
                    <Menu.Item key={`application-${index}`}>
                      {() => (
                        <div
                          onClick={() =>
                            org ? window.location.replace(`${app.url}/${org.code}`) : window.location.replace(app.url)
                          }
                          className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                          data-testid='nav-app'
                        >
                          <AppIcon code={app.code} />
                        </div>
                      )}
                    </Menu.Item>
                  )
                })}
                <Menu.Item key='portal'>
                  <div
                    onClick={() =>
                      window.location.replace(
                        `https://portal${
                          process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                        }.sistemiv.com`,
                      )
                    }
                    className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                    data-testid='nav-app'
                  >
                    <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                      <span>App Portal</span>
                      <ArrowRightIcon className='w-5 h-5 ml-2' />
                    </div>
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
        <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <ImgWrapper
              className='w-8 h-8 rounded-full border-sky-500 border'
              src={`${imageBase}/${instance.getActiveAccount()?.localAccountId}/76`}
              onErrorComponent={
                <p className='bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center border-sky-500 border'>
                  {instance.getActiveAccount()
                    ? (instance.getActiveAccount()?.idTokenClaims?.given_name as string)?.[0]
                    : 'N/A'}
                </p>
              }
            />
          </Menu.Button>
          {isMobile && (
            <Transition
              as={Fragment}
              enter='transition-opacity duration-80'
              enterFrom='opacity-0'
              enterTo='opacity-40'
              leave='transition-opacity duration-80'
              leaveFrom='opacity-40'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
            </Transition>
          )}
          <Transition
            as={Fragment}
            enter={isMobile ? 'transition-transform duration-80 ease-out' : ''}
            enterFrom={isMobile ? 'transform translate-y-full' : ''}
            enterTo={isMobile ? 'transform translate-y-0' : ''}
            leave={isMobile ? 'transition-transform duration-80 ease-in' : ''}
            leaveFrom={isMobile ? 'transform translate-y-0' : ''}
            leaveTo={isMobile ? 'transform translate-y-full' : ''}
          >
            <Menu.Items
              className={
                isMobile
                  ? 'md:hidden fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg z-20 py-4 px-4 gap-y-1'
                  : 'absolute right-0 z-10 mt-2 py-4 px-4 w-56 origin-top-right flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'
              }
            >
              {isMobile && (
                <div className='md:hidden w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
              )}
              <Menu.Item key='portal'>
                <div
                  onClick={() =>
                    window.location.replace(
                      `https://portal${
                        process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                      }.sistemiv.com`,
                    )
                  }
                  className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2'
                  data-testid='nav-app'
                >
                  <div className='font-sans text-indigo-600 text-sm font-semibold flex'>
                    <span>App Portal</span>
                    <ArrowRightIcon className='w-5 h-5 ml-2' />
                  </div>
                </div>
              </Menu.Item>
              {isMobile && <div className='absolute md:hidden left-0 right-0 w-full h-px bg-gray-300'></div>}
              <Menu.Item>
                {() => (
                  <div
                    onClick={handleLogout}
                    className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                    data-testid='nav-profile-option'
                  >
                    {t('logout')}
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </NavigationBar>
  )
}

export default TopNav
