import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import WelcomePage from '../pages/WelcomePage'
import InvalidLinkPage from '../pages/InvalidLinkPage'
import AcceptInvite from '../pages/AcceptInvite'
import Logout from '../pages/Logout'
import NotAuthorized from '../pages/NotAuthorized'
import ResponsiveContextProvider from '../components/context/ResponsiveContextProvider'

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <ResponsiveContextProvider>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='/:nav' element={<Dashboard />} />
          <Route path='/:nav/:type' element={<Dashboard />} />
          <Route path='/welcome' element={<WelcomePage />} />
          <Route path='/InvalidLink' element={<InvalidLinkPage />} />
          <Route path='/accept-invite' element={<AcceptInvite />} />
          <Route path='/not-authorized' element={<NotAuthorized />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>
      </ResponsiveContextProvider>
    </BrowserRouter>
  )
}

export default AppRouter
