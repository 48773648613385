import { Base64SVG } from '@sistemiv/s-components'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import UserService from '../services/UserService'
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { loginRequest } from '../services/AuthConfig'
import { ErrorComponent } from './Dashboard'
import { portalUrl } from '../services/http-common'
import { useTranslation } from 'react-i18next'

const AcceptInvite: React.FC = () => {
  const { instance } = useMsal()
  const [first, setFirst] = useState(true)

  useEffect(() => {
    console.log('effect')
    instance
      .ssoSilent(loginRequest)
      .then((response) => {
        console.log('silent resp: ', response)
        if (response.account?.localAccountId !== instance.getActiveAccount()?.localAccountId) {
          console.log('silent resp account: ', response.account)
          instance.setActiveAccount(response.account)
        }
        setFirst(false)
      })
      .catch((err: any) => {
        console.log('effect silent err: ', err)
        if (err instanceof InteractionRequiredAuthError) {
          console.log(err)
          window.location.replace(`${portalUrl}`)
        }
      })
  }, [instance])

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Silent}
      authenticationRequest={{
        ...loginRequest,
        account: instance.getActiveAccount() ?? undefined,
      }}
      errorComponent={ErrorComponent}
    >
      {!first && <InnerComponent />}
    </MsalAuthenticationTemplate>
  )
}

const InnerComponent: FC = () => {
  const [searchParams] = useSearchParams()
  const { instance } = useMsal()
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    const accounts = instance.getAllAccounts()
    if (!accounts || !accounts[0]) return

    const organization = searchParams.get('organization')
    const membershipId = searchParams.get('membershipId')
    console.log('membershipId: ', membershipId)
    if (!organization || !membershipId) return
    UserService.acceptInvite(organization, membershipId)
      .then((res) => {
        console.log('Accept invite resp: ', res)
        window.location.replace(res.url)
        //navigate(res.data.url)
      })
      .catch((err: any) => {
        console.log('accept error:', err)
        if (err?.response?.status === 403) {
          console.log('403 error:', err)
          window.location.replace(`${portalUrl}/logout`)
        }
      })
  }, [searchParams, instance, navigate])
  return (
    <div className='flex items-center justify-center w-full pt-60 flex-col gap-y-5'>
      <Base64SVG base64image='PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI5LjE4IDExLjE2TDE2IDI0LjM0TDguODIgMTcuMThMNiAyMEwxNiAzMEwzMiAxNEwyOS4xOCAxMS4xNlpNMjAgMEM4Ljk2IDAgMCA4Ljk2IDAgMjBDMCAzMS4wNCA4Ljk2IDQwIDIwIDQwQzMxLjA0IDQwIDQwIDMxLjA0IDQwIDIwQzQwIDguOTYgMzEuMDQgMCAyMCAwWk0yMCAzNkMxMS4xNiAzNiA0IDI4Ljg0IDQgMjBDNCAxMS4xNiAxMS4xNiA0IDIwIDRDMjguODQgNCAzNiAxMS4xNiAzNiAyMEMzNiAyOC44NCAyOC44NCAzNiAyMCAzNloiIGZpbGw9IiMyN0FBQjIiLz4KPC9zdmc+Cg==' />
      <p className='text-xl'>{t('welcomeToSIstemiv')}</p>
      <p className='text-sm'>{t('redirecting')}</p>
    </div>
  )
}

export default AcceptInvite
