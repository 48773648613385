import React, { FC, useEffect } from 'react'
import AppRouter from './router/AppRouter'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import toast, { Toaster } from 'react-hot-toast'
import { MsalProvider } from '@azure/msal-react'

const App: FC<{ pca: any }> = ({ pca }) => {
  const handleError = (error: any): string => {
    let errorMessage = ''
    const response = error.response
    const data = response?.data
    const status = response?.status
    if (status === 404) {
      errorMessage = data?.message ?? 'Resource not found'
    } else if (status === 403) {
      errorMessage = 'Unauthorized request'
    } else {
      errorMessage = data?.errorMessage ?? 'An error occurred!'
    }
    return errorMessage
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
      mutations: {
        onError: (error) => {
          console.log(error)
          toast.error(handleError(error))
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        console.log(error)
        toast.error(handleError(error))
      },
    }),
  })

  useEffect(() => {
    console.log(process.env.REACT_APP_STAGE, 'environment')
  }, [])

  return (
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
        <Toaster position='bottom-right' reverseOrder={false} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MsalProvider>
  )
}

export default App
