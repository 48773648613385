import { AppRolesResponse, ApplicationsResponse, UserProfile } from '../models/UserProfile'
import http from './http-common'

class UserService {
  async get(): Promise<UserProfile> {
    return (await http.get(`/profile`)).data
  }

  async getAppRoles(organizationId: string): Promise<AppRolesResponse> {
    return (await http.get(`/${organizationId}/profile/application`)).data
  }

  async listApplications(organization: string): Promise<ApplicationsResponse> {
    return (await http.get(`/${organization}/applications/access`)).data
  }

  async updateField(field: string, body: any) {
    return (await http.post(`/profile/${field}`, body)).data
  }

  async deletePhone() {
    return (await http.delete(`/profile/phone`)).data
  }

  async updatePhoto(image: File) {
    const formData = new FormData()
    formData.append('image', image)
    return (await http.post(`/profile/image`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data
  }

  async getUserRoles(organizationId: string): Promise<{ roles: string[] }> {
    return (await http.get(`/${organizationId}/profile/roles`)).data
  }

  async updateStatus(organizationId: string, status: string) {
    return (await http.post(`/${organizationId}/profile/status`, { status })).data
  }

  async acceptInvite(organization: string, membershipId: string) {
    return (await http.get(`/${organization}/user/${membershipId}/accept-invite`)).data
  }
}

export default new UserService()
