import axios from 'axios'
import { loginRequest, msalInstance } from './AuthConfig'

const getBaseUrl = (): string => {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'api-dev.sistemiv.com'
    case 'test':
      return 'api-test.sistemiv.com'
    case 'staging':
      return 'api-staging.sistemiv.com'
    default:
      return 'api.sistemiv.com'
  }
}

const stage = process.env.REACT_APP_STAGE
export const portalUrl = `https://portal${stage && stage !== 'prod' ? `-${stage}` : ''}.sistemiv.com`
export const imageBase = `https://${getBaseUrl()}/iam/api/profile/picture`

export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

const axiosInstance = axios.create({
  baseURL: `https://${getBaseUrl()}/iam/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})
axiosInstance.defaults.maxRedirects = 0

const authInterceptor = async (config: any) => {
  const instance = msalInstance

  const { accessToken } = await instance.acquireTokenSilent({
    ...loginRequest,
    account: msalInstance.getActiveAccount() ?? undefined,
  })
  if (config.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}

axiosInstance.interceptors.request.use(authInterceptor, (error: any) => Promise.reject(error))
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && [301, 302].includes(error.response.status)) {
      const redirectUrl = error.response.headers.location
      window.location.replace(redirectUrl)
    } else {
      return Promise.reject(error)
    }
  },
)

export default axiosInstance
