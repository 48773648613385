import { Base64SVG } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const InvalidLinkPage: FC = () => {
  const { t } = useTranslation()
  return (
    <div className='flex items-center justify-center w-full pt-60 flex-col gap-y-10'>
      <Base64SVG base64image='PHN2ZyB3aWR0aD0iMTQwIiBoZWlnaHQ9IjcxIiB2aWV3Qm94PSIwIDAgMTQwIDcxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMTQuMTUzNiAzMS4wNDg4QzE2LjA5ODUgMjAuODM3OCAyNS42ODQ0IDEzLjgyMiAzNi4xMDM5IDEzLjgyMkg1Ni40NTY2QzYwLjA2ODcgMTMuODIyIDYzLjA1NTYgMTAuODM1MSA2My4wNTU2IDcuMjIzMDFDNjMuMDU1NiAzLjYxMDkzIDYwLjA2ODcgMC42MjQwMjMgNTYuNDU2NiAwLjYyNDAyM0gzNi43OTg1QzE4LjY2ODcgMC42MjQwMjMgMi40ODM3NiAxMy44OTE1IDAuNzQ3MTg0IDMxLjk1MTlDLTEuMjY3MjUgNTIuNjUxOSAxNC45ODcxIDcwLjA4NzEgMzUuMjcwMyA3MC4wODcxSDU2LjQ1NjZDNjAuMDY4NyA3MC4wODcxIDYzLjA1NTYgNjcuMTAwMiA2My4wNTU2IDYzLjQ4ODFDNjMuMDU1NiA1OS44NzYgNjAuMDY4NyA1Ni44ODkxIDU2LjQ1NjYgNTYuODg5MUgzNS4yNzAzQzIyLjAwMjkgNTYuODg5MSAxMS41MTQgNDQuODAyNSAxNC4xNTM2IDMxLjA0ODhaTTQ5LjE2MyA0Mi4zMDE5SDkwLjg0MDhDOTQuNjYxMyA0Mi4zMDE5IDk3Ljc4NzEgMzkuMTc2IDk3Ljc4NzEgMzUuMzU1NkM5Ny43ODcxIDMxLjUzNTEgOTQuNjYxMyAyOC40MDkzIDkwLjg0MDggMjguNDA5M0g0OS4xNjNDNDUuMzQyNSAyOC40MDkzIDQyLjIxNjYgMzEuNTM1MSA0Mi4yMTY2IDM1LjM1NTZDNDIuMjE2NiAzOS4xNzYgNDUuMzQyNSA0Mi4zMDE5IDQ5LjE2MyA0Mi4zMDE5Wk0xMDMuMjA1IDAuNjI0MDIzSDgzLjU0NzJDNzkuOTM1MSAwLjYyNDAyMyA3Ni45NDgyIDMuNjEwOTMgNzYuOTQ4MiA3LjIyMzAxQzc2Ljk0ODIgMTAuODM1MSA3OS45MzUxIDEzLjgyMiA4My41NDcyIDEzLjgyMkgxMDMuOUMxMTQuMzE5IDEzLjgyMiAxMjMuOTA1IDIwLjgzNzggMTI1Ljg1IDMxLjA0ODhDMTI4LjQ5IDQ0LjgwMjUgMTE4LjAwMSA1Ni44ODkxIDEwNC43MzMgNTYuODg5MUg4My41NDcyQzc5LjkzNTEgNTYuODg5MSA3Ni45NDgyIDU5Ljg3NiA3Ni45NDgyIDYzLjQ4ODFDNzYuOTQ4MiA2Ny4xMDAyIDc5LjkzNTEgNzAuMDg3MSA4My41NDcyIDcwLjA4NzFIMTA0LjczM0MxMjUuMDE3IDcwLjA4NzEgMTQxLjI3MSA1Mi42NTE5IDEzOS4zMjYgMzEuOTUxOUMxMzcuNTg5IDEzLjg5MTUgMTIxLjMzNSAwLjYyNDAyMyAxMDMuMjA1IDAuNjI0MDIzWiIgZmlsbD0iIzAwOTZGRSIvPgo8L3N2Zz4K' />
      <p className='text-4xl '>{t('linkExpired')}</p>
      <button
        className='border-none bg-white text-blue-500 text-lg'
        onClick={() => {
          window.location.replace(`https://portal-${process.env.REACT_APP_STAGE}.sistemiv.com/?app=s-identity`)
        }}
      >
        {t('login')}
      </button>
    </div>
  )
}

export default InvalidLinkPage
