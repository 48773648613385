import { CameraIcon } from '@heroicons/react/24/solid'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import {
  DeleteModal,
  //SuccessNotification,
  SpinnerIcon,
  ImgWrapper,
  ChangePhoneModal,
  ChangeEmailModal,
  RemovePhoneModal,
  Select,
  Option,
} from '@sistemiv/s-components'
import { UserIcon } from '@heroicons/react/24/outline'
import { useUserAppRoles } from '../../repositories/users/user-app-roles.repository'
import { useUser } from '../../repositories/users/user.repository'
import { useUpdateUserInfo } from '../../repositories/users/mutations/user-update-info.mutation'
import { useDeleteUserPhone } from '../../repositories/users/mutations/user-delete-phone.mutation'
import { useUpdateUserPhoto } from '../../repositories/users/mutations/user-update-photo.mutation'
import { useUpdateUserStatus } from '../../repositories/users/mutations/user-update-status.mutation'
import { useMyOrganizations } from '../../repositories/organizations/organizations-my.respository'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import AboutMe from './AboutMe'
import OrganizationPermissionsSection from './organization-permissions/OrganizationPermissionsSection'
//import ChangePasswordModal from './ChangePassword'
import { OrgType } from '../layout/TopNav'
import Organizations from '../organizations/Organizations'
import ContactDetails from './ContactDetails'
import { useResponsive } from '../context/ResponsiveContextProvider'

const MyAccount: FC = () => {
  const { isMobile } = useResponsive()
  const [activeTab, setActiveTab] = useState('aboutMe')
  const [changePhoneNumberOpen, setChangePhoneNumberOpen] = useState(false)
  const [changeEmailOpen, setChangeEmailOpen] = useState(false)
  const [removePhoneOpen, setRemovePhoneOpen] = useState(false)
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false)
  // const [changePasswordOpen, setChangePasswordOpen] = useState(false)
  // const [successNotificationOpen, setSuccessNotificationOpen] = useState(false)
  // const [successMessage, setSuccessMessage] = useState('')
  const photoInputRef = useRef<HTMLInputElement>(null)
  const [selectedOrganization, setSelectedOrganization] = useState<OrgType | null>(null)
  //const [accountImageUrl, setAccountImageUrl] = useState('')
  const { t } = useTranslation()

  const { instance } = useMsal()

  const currentId = useMemo(() => {
    const accounts = instance.getAllAccounts()
    if (accounts[0]) {
      return accounts[0].localAccountId
    }
    return undefined
  }, [instance])

  const { data: user, isLoading: userLoading, isFetching: fetchingUser } = useUser({ userId: currentId })
  const { data: organizationsPages, isLoading: organizationsLoading } = useMyOrganizations({ userId: currentId })
  const { data: appRoles, isLoading: appRolesLoading } = useUserAppRoles({
    userId: currentId,
    organizationId: selectedOrganization?.code,
  })
  const { mutate: updateUserField, isPending: infoUpdating } = useUpdateUserInfo()
  const { mutate: deleteUserPhone, isPending: phoneDeleting } = useDeleteUserPhone()
  const { mutate: updateUserPhoto, isPending: photoUpdating } = useUpdateUserPhoto()
  const { mutate: updateUserStatus, isPending: statusUpdating } = useUpdateUserStatus()
  const organizations: OrgType[] = useMemo(
    () =>
      organizationsPages?.pages
        .flat(Infinity)
        .map((r) => r.organizations)
        .flat()
        ?.map((o) => ({ id: o.organizationCode, code: o.organizationCode, image: o.icon, name: o.name })) ?? [],
    [organizationsPages],
  )

  // useEffect(() => {
  //   console.log('effect')
  //   setAccountImageUrl(`${imageBase}/${user?.data.id}/180?p=${new Date().getMilliseconds()}`)
  // }, [user, fetchingUser])

  useEffect(() => {
    if (organizations.length > 0 && !selectedOrganization) {
      setSelectedOrganization(organizations[0])
    }
  }, [organizations, selectedOrganization])

  const loading =
    userLoading ||
    organizationsLoading ||
    appRolesLoading ||
    infoUpdating ||
    phoneDeleting ||
    photoUpdating ||
    statusUpdating

  const keyMapper = (key: string) => {
    switch (key) {
      case 'job-title':
        return 'jobTitle'
      case 'first-name':
        return 'firstName'
      case 'last-name':
        return 'lastName'
      case 'post-code':
        return 'postCode'
      case 'time-zone':
        return 'timeZone'
      default:
        return key
    }
  }

  const handleUpdateField = (key: string, value: string) => {
    if (!currentId) return
    updateUserField({ userId: currentId, field: key, body: { [keyMapper(key)]: value } })
  }

  const handleDeletePhone = () => {
    if (!currentId) return
    deleteUserPhone({ userId: currentId })
  }

  const handlePhotoUpdate = (e: any) => {
    const file = e.target.files.length > 0 ? e.target.files[0] : null
    if (!file || !user) return
    updateUserPhoto({ userId: user.data.id, image: file })
  }
  const handleDeleteAccount = () => {
    if (!currentId || !selectedOrganization) return
    updateUserStatus({ userId: currentId, organizationId: selectedOrganization.id, status: 'Delete' })
    setDeleteAccountOpen(false)
  }

  return (
    <div className='h-full flex flex-col overflow-hidden relative'>
      {loading && (
        <div className='w-full flex justify-center top-5 inset-x-auto absolute'>
          <SpinnerIcon className='w-8 h-8 text-sky-500' />
        </div>
      )}
      <div className={`h-full w-full flex flex-col overflow-hidden ${loading ? 'opacity-50' : 'opacity-100'}`}>
        <div className='flex-none px-8 py-6 bg-gradient-to-b from-[#E1E2EA] to-white ${}'>
          <div
            onClick={() => {
              if (photoInputRef.current) {
                photoInputRef.current.click()
              }
            }}
            className='group w-20 h-20 rounded-full border-2 border-sky-500 relative overflow-hidden flex items-center justify-center bg-[#7B87AE] text-white select-none cursor-pointer'
          >
            <ImgWrapper
              src={`${imageBase}/${user?.data.id}/76?p=${fetchingUser ? '' : new Date().getMilliseconds()}`}
              className='w-full h-full'
              onErrorComponent={
                <p className='font-bold text-6xl'>
                  {user ? user.data?.firstName?.charAt(0) : <UserIcon className='w-full h-full text-white' />}
                </p>
              }
            />
            <span className='absolute z-10 flex justify-center items-center bottom-0 bg-black bg-opacity-30 group-hover:bg-opacity-50 w-full py-0.5'>
              <CameraIcon className='w-4 h-4' />
            </span>
          </div>
          <input
            type='file'
            className='hidden'
            accept='image/png,image/jpg,image/jpeg'
            ref={photoInputRef}
            onChange={handlePhotoUpdate}
          />
        </div>
        <div className={`grow overflow-auto ${!isMobile && 'px-8 py-6'}`}>
          {isMobile ? (
            <div className='px-5 flex flex-col grow overflow-auto overflow-y-auto'>
              <div className='relative flex justify-start gap-x-8 text-slate-600 flex-none'>
                <div className='absolute bottom-0 w-full h-0.5 z-0 bg-gray-300 ' data-testid='tabs-panel' />
                <div
                  key={`tab-aboutMe`}
                  onClick={() => setActiveTab('aboutMe')}
                  data-testid={`tab-aboutMe`}
                  className={`relative z-10 py-3 cursor-pointer border-b-2 whitespace-nowrap ${
                    activeTab === 'aboutMe'
                      ? 'border-blue-500 text-blue-500 hover:border-blue-500'
                      : 'border-gray-300 hover:border-black'
                  }`}
                >
                  {t('aboutMe')}
                </div>
                <div
                  key={`tab-contactDetails`}
                  onClick={() => setActiveTab('contactDetails')}
                  data-testid={`tab-contactDetails`}
                  className={`relative z-10 py-3 cursor-pointer border-b-2 whitespace-nowrap ${
                    activeTab === 'contactDetails'
                      ? 'border-blue-500 text-blue-500 hover:border-blue-500'
                      : 'border-gray-300 hover:border-black'
                  }`}
                >
                  {t('contactDetailsMobile')}
                </div>
                <div
                  key={`tab-organization`}
                  onClick={() => setActiveTab('organization')}
                  data-testid={`tab-organization`}
                  className={`relative z-10 py-3 cursor-pointer border-b-2 whitespace-nowrap ${
                    activeTab === 'organization'
                      ? 'border-blue-500 text-blue-500 hover:border-blue-500'
                      : 'border-gray-300 hover:border-black'
                  }`}
                >
                  {t('organization')}
                </div>
              </div>
              <div className='pb-2 grow'>
                {activeTab === 'aboutMe' ? (
                  <AboutMe
                    firstName={user?.data.firstName ?? ''}
                    lastName={user?.data.lastName ?? ''}
                    jobTitle={user?.data.jobTitle ?? ''}
                    company={user?.data.company ?? ''}
                    email={user?.data.email ?? ''}
                    onChangeEmail={() => {
                      setChangeEmailOpen(true)
                    }}
                    isSocialAccount={!!instance.getActiveAccount()?.idTokenClaims?.idp}
                    onChangePassword={() => {
                      window.location.replace(
                        `https://sistemiv.b2clogin.com/sistemiv.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PASSWORD_RESET&client_id=fd1442d1-cbcc-4b73-a893-a61b8d3d4782&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fidentity-staging.sistemiv.com&scope=openid&response_type=id_token`,
                      )
                    }}
                    onEdit={handleUpdateField}
                    status={''}
                  />
                ) : activeTab === 'contactDetails' ? (
                  <ContactDetails
                    phone={user?.data.phone ?? ''}
                    address={user?.data.address ?? ''}
                    city={user?.data.city ?? ''}
                    postCode={user?.data.postalCode ?? ''}
                    country={user?.data.countryId ?? ''}
                    timeZone={user?.data.timeZoneId ?? ''}
                    onAddPhoneNumber={() => {
                      setChangePhoneNumberOpen(true)
                    }}
                    onChangePhoneNumber={() => {
                      setChangePhoneNumberOpen(true)
                    }}
                    onRemovePhoneNumber={() => setRemovePhoneOpen(true)}
                    onEdit={handleUpdateField}
                  />
                ) : activeTab === 'organization' ? (
                  <div className='pt-8 space-y-5'>
                    {organizations.length > 0 ? (
                      <>
                        <Select
                          type='organization'
                          value={selectedOrganization ?? undefined}
                          disabled={organizations.length <= 1}
                          onChange={(e) => {
                            setSelectedOrganization(e)
                          }}
                        >
                          {organizations
                            .filter((o) => o.code !== selectedOrganization?.code)
                            .map((o) => (
                              <Option key={o.code} value={o}>
                                <div className='pb-1 hover:bg-blue-50 cursor-pointer flex'>
                                  <ImgWrapper
                                    src={`${imageBase}/${o.code}/76`}
                                    className='w-6 h-6 rounded-full mr-2 flex items-center justify-center border border-gray-300'
                                    onErrorComponent={
                                      <p className='text-md text-white w-6 h-6 mr-2 rounded-full flex items-center justify-center bg-[#7b87ae]'>
                                        {o.name.charAt(0).toUpperCase()}
                                      </p>
                                    }
                                  />
                                  <span> {o.name}</span>
                                </div>
                              </Option>
                            ))}
                        </Select>
                        {/* <OrganizationCombobox
                options={organizations}
                value={selectedOrganization ?? undefined}
                onSelect={setSelectedOrganization}
              /> */}
                        {appRoles && (
                          <OrganizationPermissionsSection
                            //canUpdateStatus={userRoles?.roles.includes('org-admin')}
                            status={appRoles.status}
                            apps={appRoles.applications ?? []}
                            groups={appRoles.groups ?? []}
                            units={appRoles.units ?? []}
                            //onChangeStatus={handleChangeStatus}
                          />
                        )}
                      </>
                    ) : (
                      <Organizations type='section' />
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : (
            <div className='grid grid-cols-3 w-5/6 gap-x-6'>
              <div>
                <p className='text-base text-gray-800 font-semibold'>{t('aboutMe')}</p>
                <AboutMe
                  firstName={user?.data.firstName ?? ''}
                  lastName={user?.data.lastName ?? ''}
                  jobTitle={user?.data.jobTitle ?? ''}
                  company={user?.data.company ?? ''}
                  email={user?.data.email ?? ''}
                  onChangeEmail={() => {
                    setChangeEmailOpen(true)
                  }}
                  isSocialAccount={!!instance.getActiveAccount()?.idTokenClaims?.idp}
                  onChangePassword={() => {
                    window.location.replace(
                      `https://sistemiv.b2clogin.com/sistemiv.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PASSWORD_RESET&client_id=fd1442d1-cbcc-4b73-a893-a61b8d3d4782&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fidentity-staging.sistemiv.com&scope=openid&response_type=id_token`,
                    )
                  }}
                  onEdit={handleUpdateField}
                  status={''}
                />
              </div>
              <div>
                <p className='text-base text-gray-800 font-semibold'>{t('contactDetails')}</p>
                <ContactDetails
                  phone={user?.data.phone ?? ''}
                  address={user?.data.address ?? ''}
                  city={user?.data.city ?? ''}
                  postCode={user?.data.postalCode ?? ''}
                  country={user?.data.countryId ?? ''}
                  timeZone={user?.data.timeZoneId ?? ''}
                  onAddPhoneNumber={() => {
                    setChangePhoneNumberOpen(true)
                  }}
                  onChangePhoneNumber={() => {
                    setChangePhoneNumberOpen(true)
                  }}
                  onRemovePhoneNumber={() => setRemovePhoneOpen(true)}
                  onEdit={handleUpdateField}
                />
              </div>

              <div className='space-y-5'>
                <p className='text-base text-gray-800 font-semibold'>{t('organization')}</p>
                {organizations.length > 0 ? (
                  <>
                    <Select
                      type='organization'
                      value={selectedOrganization ?? undefined}
                      disabled={organizations.length <= 1}
                      onChange={(e) => {
                        setSelectedOrganization(e)
                      }}
                    >
                      {organizations
                        .filter((o) => o.code !== selectedOrganization?.code)
                        .map((o) => (
                          <Option key={o.code} value={o}>
                            <div className='pb-1 hover:bg-blue-50 cursor-pointer flex'>
                              <ImgWrapper
                                src={`${imageBase}/${o.code}/76`}
                                className='w-6 h-6 rounded-full mr-2 flex items-center justify-center border border-gray-300'
                                onErrorComponent={
                                  <p className='text-md text-white w-6 h-6 mr-2 rounded-full flex items-center justify-center bg-[#7b87ae]'>
                                    {o.name.charAt(0).toUpperCase()}
                                  </p>
                                }
                              />
                              <span> {o.name}</span>
                            </div>
                          </Option>
                        ))}
                    </Select>
                    {/* <OrganizationCombobox
                options={organizations}
                value={selectedOrganization ?? undefined}
                onSelect={setSelectedOrganization}
              /> */}
                    {appRoles && (
                      <OrganizationPermissionsSection
                        //canUpdateStatus={userRoles?.roles.includes('org-admin')}
                        status={appRoles.status}
                        apps={appRoles.applications ?? []}
                        groups={appRoles.groups ?? []}
                        units={appRoles.units ?? []}
                        //onChangeStatus={handleChangeStatus}
                      />
                    )}
                  </>
                ) : (
                  <Organizations type='section' />
                )}
              </div>
            </div>
          )}
        </div>
        {changePhoneNumberOpen && (
          <ChangePhoneModal
            open={changePhoneNumberOpen}
            setOpen={setChangePhoneNumberOpen}
            phone={user?.data.phone ?? ''}
            onContinue={(value) => {
              handleUpdateField('phone', value)
              setChangePhoneNumberOpen(false)
            }}
          />
        )}
        {changeEmailOpen && (
          <ChangeEmailModal
            open={changeEmailOpen}
            setOpen={setChangeEmailOpen}
            email={user?.data.email ?? ''}
            onContinue={(value) => {
              handleUpdateField('email', value)
              setChangeEmailOpen(false)
            }}
          />
        )}
        {removePhoneOpen && (
          <RemovePhoneModal
            open={removePhoneOpen}
            setOpen={setRemovePhoneOpen}
            onRemoveConfirmed={() => {
              handleDeletePhone()
              setRemovePhoneOpen(false)
            }}
          />
        )}
        {deleteAccountOpen && (
          <DeleteModal
            open={deleteAccountOpen}
            setOpen={setDeleteAccountOpen}
            title={t('deleteAcc')}
            description={t('deleteMsg')}
            onDeleteConfirmed={handleDeleteAccount}
          />
        )}
        {/* {changePasswordOpen && (
          <ChangePasswordModal
            open={changePasswordOpen}
            setOpen={setChangePasswordOpen}
            onContinue={() => {
              setChangePasswordOpen(false)
              setSuccessMessage(t('successfullyChangedPass') as string)
              setSuccessNotificationOpen(true)
            }}
          />
        )} */}
        {/* {successNotificationOpen && (
          <SuccessNotification
            open={successNotificationOpen}
            setOpen={setSuccessNotificationOpen}
            message={successMessage}
            adminApproval={false}
          />
        )} */}
      </div>
    </div>
  )
}

export default MyAccount
