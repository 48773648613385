import { Base64SVG } from '@sistemiv/s-components'
import React, { FC, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { portalUrl } from '../services/http-common'

const WelcomePage: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      // navigate('/')
      window.location.replace(`${portalUrl}/`)
    }, 2000)
  }, [navigate])

  return (
    <div className='flex items-center justify-center w-full pt-60 flex-col gap-y-5'>
      <Base64SVG base64image='PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI5LjE4IDExLjE2TDE2IDI0LjM0TDguODIgMTcuMThMNiAyMEwxNiAzMEwzMiAxNEwyOS4xOCAxMS4xNlpNMjAgMEM4Ljk2IDAgMCA4Ljk2IDAgMjBDMCAzMS4wNCA4Ljk2IDQwIDIwIDQwQzMxLjA0IDQwIDQwIDMxLjA0IDQwIDIwQzQwIDguOTYgMzEuMDQgMCAyMCAwWk0yMCAzNkMxMS4xNiAzNiA0IDI4Ljg0IDQgMjBDNCAxMS4xNiAxMS4xNiA0IDIwIDRDMjguODQgNCAzNiAxMS4xNiAzNiAyMEMzNiAyOC44NCAyOC44NCAzNiAyMCAzNloiIGZpbGw9IiMyN0FBQjIiLz4KPC9zdmc+Cg==' />

      <p className='text-xl'>
        Hi {searchParams.get('name')}, welcome to {searchParams.get('organization') ?? 'Sistemiv'}
      </p>
    </div>
  )
}

export default WelcomePage
