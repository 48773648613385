import React, { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MyAccount from '../my-account/MyAccount'
import Organizations from '../organizations/Organizations'
import ErrorHandlingInterceptor from '../error-handling/ErrorHandlingInterceptor'

const DashboardSwitch: FC = () => {
  const { nav, type } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    console.log('SideNavMenu loaded')
    if (!nav || !type) {
      navigate('/profile/my-account')
    }
  }, [nav, type, navigate])

  switch (nav) {
    case 'profile':
      return type === 'my-account' ? (
        <ErrorHandlingInterceptor>
          <MyAccount />
        </ErrorHandlingInterceptor>
      ) : type === 'organizations' ? (
        <ErrorHandlingInterceptor>
          <Organizations />
        </ErrorHandlingInterceptor>
      ) : null
    default:
      return null
  }
}

export default DashboardSwitch
