import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { ApplicationsResponse } from '../../models/UserProfile'

export const useGetApplications = (org: string) => {
  return useQuery<ApplicationsResponse>({
    queryKey: ['applications', org],
    queryFn: () => UserService.listApplications(org!),
    enabled: !!org,
    staleTime: Infinity,
  })
}
