import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  SuccessNotification,
  CreateOrganizationModal,
  JoinOrganizationModal,
  ImgWrapper,
  // NotificationModal,
} from '@sistemiv/s-components'
import { useMsal } from '@azure/msal-react'
import { useCreateOrganization } from '../../repositories/organizations/mutations/create-organization.mutation'
import { useMyOrganizations } from '../../repositories/organizations/organizations-my.respository'
import { useOrganizations } from '../../repositories/organizations/organizations.respository'
import { useJoinOrganization } from '../../repositories/organizations/mutations/join-organization.mutation'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'

type Props = {
  type?: 'section' | 'page'
}

const Organizations: FC<Props> = ({ type = 'page' }) => {
  const { instance } = useMsal()
  const currentId = useMemo(() => {
    const accounts = instance.getAllAccounts()
    if (accounts[0]) {
      return accounts[0].localAccountId
    }
    return undefined
  }, [instance])
  const [createOrganizationOpen, setCreateOrganizationOpen] = useState(false)
  const [joinOrganizationOpen, setJoinOrganizationOpen] = useState(false)
  const [notificationModalOpen, setNotificationModalOpen] = useState(false)
  // const [editModalOpen, setEditModalOpen] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')
  const [search, setSearch] = useState('')
  //const [editedNotificationsOrgsIds, setEditedNotificationsOrgsIds] = useState<string[]>([])
  //const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null)
  const { t } = useTranslation()
  const {
    data: myOrgPages,
    fetchNextPage: fetchNextMyOrgs,
    hasNextPage: hasNextMyOrgs,
    isFetching: isFetchingMyOrg,
  } = useMyOrganizations({ userId: currentId })
  const {
    data: orgPages,
    fetchNextPage: fetchNextOrgs,
    refetch,
  } = useOrganizations({ userId: currentId, search: search })
  const { mutate: createOrganization, isPending: isCreating } = useCreateOrganization()
  const { mutate: joinOrganization, isPending: isJoining } = useJoinOrganization()
  const listRef = useRef<HTMLDivElement>(null)

  const organizations = myOrgPages?.pages
    .flat(Infinity)
    .map((r) => r.organizations)
    .flat()
    ?.map((o) => ({ id: o.organizationCode, image: o.icon, name: o.name }))

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      if (!hasNextMyOrgs || isFetchingMyOrg) return
      fetchNextMyOrgs()
    }
  }
  useEffect(() => {
    if (joinOrganizationOpen) refetch()
  }, [joinOrganizationOpen, refetch])

  useEffect(() => {
    if (listRef && listRef.current) {
      const hasScrollbar = listRef.current.scrollHeight > listRef.current.clientHeight
      if (!hasScrollbar) {
        fetchNextMyOrgs()
      }
    }
  }, [fetchNextMyOrgs, myOrgPages])

  // const handleEditedNotificationsOrgsIds = (): void => {
  //   if (selectedOrgId) {
  //     if (editedNotificationsOrgsIds.includes(selectedOrgId)) {
  //       setEditedNotificationsOrgsIds(editedNotificationsOrgsIds.filter((id) => id !== selectedOrgId))
  //     } else {
  //       setEditedNotificationsOrgsIds([...editedNotificationsOrgsIds, selectedOrgId])
  //     }
  //   }
  // }

  return (
    <>
      {type === 'page' ? (
        <div className='p-8 space-y-9 h-full'>
          <h2 className='text-2xl'>{t('yourOrganizations')}</h2>
          {(!organizations || organizations.length === 0) && <div className='text-sm'>{t('organizationsText')}</div>}
          <div className='flex items-center gap-x-3'>
            {/* <Button
              className='bg-white text-blue-500 border border-blue-500'
              onClick={() => setCreateOrganizationOpen(true)}
            >
              {t('createOrganization')}
            </Button> */}
            <Button className='bg-blue-500 text-white' onClick={() => setJoinOrganizationOpen(true)}>
              {t('joinOrganization')}
            </Button>
          </div>
          {organizations && organizations.length > 0 && (
            <div className='w-full h-4/5 overflow-auto pb-5' ref={listRef} onScroll={handleScroll}>
              <div className='grid grid-cols-1 gap-y-6'>
                {organizations.map((organization, index) => (
                  <React.Fragment key={`organization-${index}`}>
                    <div className='flex items-center gap-x-4'>
                      <span
                        className={`w-9 h-9 rounded-full overflow-hidden border border-gray-300 flex items-center justify-center bg-[#7B87AE]`}
                      >
                        <ImgWrapper
                          src={`${imageBase}/${organization.id}/76`}
                          className='w-full h-full'
                          onErrorComponent={
                            <p className='text-lg text-white'>{organization?.name?.charAt(0)?.toUpperCase()}</p>
                          }
                        />
                      </span>
                      <p className='font-semibold'>{organization.name}</p>
                    </div>
                    {/* <div>
            <Button
              className='text-blue-500'
              onClick={() =>   //   setEditModalOpen(true)
            //   setSelectedOrgId(organization.id)}
            >
              Edit notifications
            </Button>
          </div> */}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='flex flex-col items-start gap-y-5'>
          {/* <Button
            className='bg-white text-blue-500 border border-blue-500 w-52'
            onClick={() => setCreateOrganizationOpen(true)}
          >
            {t('createOrganization')}
          </Button> */}
          <Button className='bg-blue-500 text-white w-52' onClick={() => setJoinOrganizationOpen(true)}>
            {t('joinOrganization')}
          </Button>
        </div>
      )}
      {joinOrganizationOpen && (
        <JoinOrganizationModal
          open={joinOrganizationOpen}
          setOpen={setJoinOrganizationOpen}
          onJoin={(organization) => {
            joinOrganization(
              { code: organization.code },
              {
                onSuccess: () => {
                  setJoinOrganizationOpen(false)
                  setNotificationMessage(t('joinOrgMsg') as string)
                  setNotificationModalOpen(true)
                },
              },
            )
          }}
          isJoining={isJoining}
          fetchNext={() => fetchNextOrgs()}
          setSearch={(value: string) => setSearch(value)}
          options={
            orgPages?.pages
              .flat(Infinity)
              .map((r) => r.organizations)
              .flat()
              ?.map((o) => ({
                id: o.organizationCode,
                code: o.organizationCode,
                image: `${imageBase}/${o.organizationCode}/76`,
                name: o.name,
              })) ?? []
          }
        />
      )}
      {createOrganizationOpen && (
        <CreateOrganizationModal
          isCreating={isCreating}
          open={createOrganizationOpen}
          setOpen={setCreateOrganizationOpen}
          onContinue={(name: string, subdomain: string) => {
            createOrganization(
              { name, code: subdomain },
              {
                onSuccess: () => {
                  setCreateOrganizationOpen(false)
                  setNotificationMessage(t('createOrgMsg', { name: name }) as string)
                  setNotificationModalOpen(true)
                },
              },
            )
          }}
        />
      )}
      {notificationModalOpen && (
        <SuccessNotification
          open={notificationModalOpen}
          setOpen={setNotificationModalOpen}
          message={notificationMessage}
        />
      )}
      {/* {editModalOpen && (
      <NotificationModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        checked={selectedOrgId ? editedNotificationsOrgsIds.includes(selectedOrgId) : false}
        onChecked={() => handleEditedNotificationsOrgsIds()}
      />
    )} */}
    </>
  )
}

export default Organizations
