import React, { createContext, useState, useEffect, useContext, PropsWithChildren, FC } from 'react'

interface ResponsiveContextType {
  isMobile: boolean
}

const initialContext: ResponsiveContextType = {
  isMobile: false,
}

export const ResponsiveContext = createContext<ResponsiveContextType>(initialContext)

const ResponsiveContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768)

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout

    const handleResize = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 768)
      }, 200)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      clearTimeout(resizeTimeout)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <ResponsiveContext.Provider value={{ isMobile }}>{children}</ResponsiveContext.Provider>
}

export default ResponsiveContextProvider

export const useResponsive = () => {
  return useContext(ResponsiveContext)
}
