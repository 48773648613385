import React, { FC } from 'react'
import OrganizationPermissionsApp from './OrganizationPermissionsApp'
import { useTranslation } from 'react-i18next'
//import { Menu } from '@headlessui/react'
//import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

export type Application = {
  id: string
  name: string
  accessible: boolean
  roles?: { id: string; name: string }[]
}

type OrganizationPermissionsSectionProps = {
  status: string
  //onChangeStatus?(status: string): void
  apps: Application[]
  groups: string[]
  units: string[]
  //canUpdateStatus?: boolean
}

const OrganizationPermissionsSection: FC<OrganizationPermissionsSectionProps> = ({
  status,
  //onChangeStatus,
  apps,
  groups,
  units,
  //canUpdateStatus,
}) => {
  const { t } = useTranslation()
  return (
    <div className='space-y-5'>
      <OrganizationPermissionsApp readonly apps={apps} />
      <div className='space-y-3'>
        <p className='text-slate-400 text-sm'>{t('orgPositions')}</p>
        {units.map((unit, index) => (
          <p key={`group-${unit}-${index}`}>{unit}</p>
        ))}
      </div>
      <div className='space-y-3'>
        <p className='text-slate-400 text-sm'>{t('groups')}</p>
        {groups.map((group, index) => (
          <p key={`group-${group}-${index}`}>{group}</p>
        ))}
      </div>
      <div className='space-y-1'>
        <label className='text-gray-500'>{t('status')}</label>
        <div className='flex items-center justify-stretch mb-4'>
          <span className='mr-2'>{status}</span>
          {/* {canUpdateStatus && (
            <Menu as='div' className='relative'>
              <Menu.Button className={'relative flex items-center justify-center'}>
                <EllipsisVerticalIcon className='h-5 w-5 text-gray-500' />
              </Menu.Button>
              <Menu.Items className='absolute mt-1 py-1 left-0 max-w-[10rem] w-screen bg-white rounded shadow-xl ring-1 ring-black ring-opacity-5'>
                <Menu.Item>
                  <div
                    className='bg-white px-3 py-2 hover:bg-blue-50 active:bg-blue-100 cursor-pointer'
                    onClick={() => onChangeStatus && onChangeStatus(status === 'Active' ? 'Inactive' : 'Active')}
                  >
                    {status === 'Active' ? 'Deactivate' : 'Activate'}
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className='bg-white px-3 py-2 hover:bg-blue-50 active:bg-blue-100 cursor-pointer'
                    onClick={() => onChangeStatus && onChangeStatus('Delete')}
                  >
                    Delete
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default OrganizationPermissionsSection
