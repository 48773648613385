import { SideMenu, SideMenuItem } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const SideNavMenu: FC<{ isSideMenuOpen: boolean }> = ({ isSideMenuOpen }) => {
  const { nav, type } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <SideMenu isOpen={isSideMenuOpen}>
      <SideMenuItem
        classes={`flex items-center gap-x-3 py-3 px-8 ${
          nav === 'profile' ? 'text-white bg-blue-500 hover:!bg-blue-500' : 'text-gray-700 bg-white'
        }`}
      >
        <UserCircleIcon className={`w-5 h-5 flex-none`} />
        <p>{t('profile')}</p>
      </SideMenuItem>
      <SideMenuItem
        onClick={() => navigate('/profile/my-account')}
        classes={`py-3 px-11 ${
          type === 'my-account' ? 'text-blue-500 bg-blue-50 hover:!bg-blue-50' : 'text-gray-700 bg-white'
        }`}
      >
        <p>{t('myAccount')}</p>
      </SideMenuItem>
      <SideMenuItem
        onClick={() => navigate('/profile/organizations')}
        classes={`py-3 px-11 ${
          type === 'organizations' ? 'text-blue-500 bg-blue-50 hover:!bg-blue-50' : 'text-gray-700 bg-white'
        }`}
      >
        <p>{t('organizations')}</p>
      </SideMenuItem>
    </SideMenu>
  )
}

export default SideNavMenu
