import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationService from '../../../services/Organization.service'

export type JoinOrganizationPayload = {
  code: string
}

export const useJoinOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, JoinOrganizationPayload>({
    mutationKey: ['joinOrganization'],
    mutationFn: (payload) => OrganizationService.join(payload.code),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['my-organizations'] })
      queryClient.invalidateQueries({ queryKey: ['organizations'] })
    },
  })
}
