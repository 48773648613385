import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'

type UpdateUserInfoOptions = {
  userId: string
  field: string
  body: any
}

export const useUpdateUserInfo = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateUserInfoOptions>({
    mutationKey: ['updateUserInfo'],
    mutationFn: (payload) => UserService.updateField(payload.field, payload.body),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['user', variables.userId] })
    },
  })
}
