import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationService from '../../../services/Organization.service'
import { CreateOrganizationRequest } from '../../../models/Organization.model'

export const useCreateOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, CreateOrganizationRequest>({
    mutationKey: ['createOrganization'],
    mutationFn: (payload) => OrganizationService.create(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['my-organizations'] })
    },
  })
}
