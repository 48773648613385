import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { SpinnerIcon } from '@sistemiv/s-components'
import { PropsWithClassName } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useEffect, useState } from 'react'

type EditInputProps = {
  label?: string
  placeholder?: string
  value?: any
  onSave?: (value: any) => void
  dataTestId?: string
}

const EditInput: FC<PropsWithClassName<EditInputProps>> = ({
  value,
  onSave,
  label,
  placeholder,
  className,
  dataTestId,
}) => {
  const [newValue, setNewValue] = useState(value)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setNewValue(value)
    setLoading(false)
  }, [value])
  return (
    <div>
      {label}
      <div className='relative'>
        <input
          data-testid={dataTestId}
          type='text'
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          placeholder={placeholder}
          className={`
            w-full py-2 pl-3 pr-16 rounded-md border border-gray-300 focus:border-sky-100 focus:ring-1 focus:ring-sky-100
           ${className ?? ''}
          `}
        />
        <span
          className={`absolute inset-y-0 items-center gap-x-2 pr-2 right-0
            ${newValue !== value ? 'flex' : 'hidden'}`}
        >
          {loading ? (
            <SpinnerIcon className='w-4 h-4 text-sky-500' />
          ) : (
            <>
              <button
                className='bg-white rounded-md border border-gray-300 p-0.5 hover:bg-slate-50'
                onClick={() => {
                  setLoading(true)
                  onSave?.(newValue)
                }}
              >
                <CheckIcon className='w-4 h-4 text-sky-500' />
              </button>
              <button
                className='bg-white rounded-md border border-gray-300 p-0.5 hover:bg-slate-50'
                onClick={() => setNewValue(value)}
              >
                <XMarkIcon className='w-4 h-4 text-gray-400' />
              </button>
            </>
          )}
        </span>
      </div>
    </div>
  )
}

export default EditInput
