import UserService from '../../services/UserService'
import { UserProfile } from '../../models/UserProfile'
import { useQuery } from '@tanstack/react-query'

type UserOptions = {
  userId?: string
}

export const useUser = ({ userId }: UserOptions) => {
  return useQuery<UserProfile>({
    queryKey: ['user', userId],
    queryFn: () => UserService.get(),
    enabled: !!userId,
  })
}
