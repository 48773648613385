import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'

type UpdateUserStatusOptions = {
  userId: string
  organizationId: string
  status: string
}

export const useUpdateUserStatus = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, UpdateUserStatusOptions>({
    mutationKey: ['updateUserStatus'],
    mutationFn: (payload) => UserService.updateStatus(payload.organizationId, payload.status),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['user', variables.userId] })
    },
  })
}
