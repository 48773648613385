import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserService from '../../../services/UserService'

type DeleteUserPhoneOptions = {
  userId: string
}

export const useDeleteUserPhone = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, DeleteUserPhoneOptions>({
    mutationKey: ['deleteUserPhone'],
    mutationFn: () => UserService.deletePhone(),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['user', variables.userId] })
    },
  })
}
